import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHero from '../components/hero/page'
import NewsCard from '../components/newscard'
import Contact from '../components/contact'
import HorizontalForm from '../components/forms/horizontal'

import styles from '../styles/page.module.scss'

const NewsPage = () => (
  <StaticQuery
    query={graphql`
      query NewsQuery {
        currentPage: allContentYaml(filter: { url: { eq:"/news" } }) {
          edges {
            node {
              title
              description
              heroLeadLine
              heroLine
              heroPoints
            }
          }
        }

        globalSettings: allContentYaml(filter: {
          type: { eq: "GlobalSettings" }
        }) {
          edges {
            node {
              callToActionTitle
            }
          }
        }

        news: allNewsYaml(sort: {
          fields: publishedTime,
          order: DESC
        }) {
          edges {
            node {
              title
              publishedTime
              content
              url
            }
          }
        }
      }
    `}
    render={data => {
      const page = data.currentPage.edges[0].node
      const settings = data.globalSettings.edges[0].node

      return (
        <Layout>
          <SEO
            title={ page.title }
            description={ page.description }
            canonical="https://motorinjuryhelpline.com.au/news"
          />

          <PageHero
            leadLine={ page.heroLeadLine }
            heroLine={ page.heroLine }
            heroPointsLead="All our lawyers provide:"
            heroPoints={ page.heroPoints }
            disableContact
          />

          <div className={ styles.newsLatest }>
            <div className={ styles.newsLatest__flex }>
              {
                data.news.edges.map((post, index) => {
                  return <NewsCard
                    title={ post.node.title }
                    content={ post.node.content }
                    url={ post.node.url }
                    key={ index }
                  />
                })
              }
            </div>
          </div>

          <div className={ styles.horizontalForm__background_padded }>
            <HorizontalForm
              formTitle={ settings.callToActionTitle }
            />
          </div>

          <Contact />

        </Layout>
      )
    }
    }
  />
)

export default NewsPage
